@import "/src/assets/scss/variables.scss";

body {
    padding-top: 82px;
}

.navbar-brand {
    color: $light;
    margin-right: 0;
}

.navbar-brand:hover {
    color: var(--bs-nav-link-hover-color);
}

.navbar-brand img {
    height: 2.5rem;
    margin-right: 10px;
}

.navbar-collapse {
    align-items: unset;
}

.navbar-nav {
    padding: 15px 0;
}

.nav-item a {
    font-weight: bold;
    line-height: 1;
}

#navbar-switch {
    display: block;
}

@media (min-width: $sm) {
    #navbar-switch {
        display: flex;
        margin-left: 10px;
        border-radius: 30px;
    }

    #navbar-switch .nav-item a {
        padding-right: 20px;
        padding-left: 20px;
    }

    #navbar-switch[data-active-page="user"],
    #navbar-switch[data-active-page="business"] {
        background-color: var(--bs-navbar-color);
    }

    #navbar-switch[data-active-page="user"] .nav-item a,
    #navbar-switch[data-active-page="business"] .nav-item a {
        color: $primary;
    }

    #navbar-switch[data-active-page="user"] .nav-item a.active,
    #navbar-switch[data-active-page="business"] .nav-item a.active {
        background-color: $light;
        border-radius: 30px;
    }
}

.navbar-uae-text {
    font-size: 14px;
    line-height: 1;
}

.navbar-flag {
    font-size: 24px;
    margin-left: 10px;
}

#navbar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1029;
    background-color: rgba($color: #000000, $alpha: .5);
    display: none;
}