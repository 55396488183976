body {
  padding-top: 82px;
}

.navbar-brand {
  color: #f8f9fa;
  margin-right: 0;
}

.navbar-brand:hover {
  color: var(--bs-nav-link-hover-color);
}

.navbar-brand img {
  height: 2.5rem;
  margin-right: 10px;
}

.navbar-collapse {
  align-items: unset;
}

.navbar-nav {
  padding: 15px 0;
}

.nav-item a {
  font-weight: bold;
  line-height: 1;
}

#navbar-switch {
  display: block;
}

@media (width >= 576px) {
  #navbar-switch {
    border-radius: 30px;
    margin-left: 10px;
    display: flex;
  }

  #navbar-switch .nav-item a {
    padding-left: 20px;
    padding-right: 20px;
  }

  #navbar-switch[data-active-page="user"], #navbar-switch[data-active-page="business"] {
    background-color: var(--bs-navbar-color);
  }

  #navbar-switch[data-active-page="user"] .nav-item a, #navbar-switch[data-active-page="business"] .nav-item a {
    color: #341f97;
  }

  #navbar-switch[data-active-page="user"] .nav-item a.active, #navbar-switch[data-active-page="business"] .nav-item a.active {
    background-color: #f8f9fa;
    border-radius: 30px;
  }
}

.navbar-uae-text {
  font-size: 14px;
  line-height: 1;
}

.navbar-flag {
  margin-left: 10px;
  font-size: 24px;
}

#navbar-overlay {
  z-index: 1029;
  background-color: #00000080;
  display: none;
  position: fixed;
  inset: 0;
}
/*# sourceMappingURL=index.2fb6813e.css.map */
